export default {
  namespaced: true,
  
  state: {
    token: null,
    user: null
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
    },

    SET_USER(state, user) {
      state.user = user
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        localStorage.removeItem('user')
      }
    },

    CLEAR_AUTH(state) {
      state.token = null
      state.user = null
      localStorage.removeItem('token')
      localStorage.removeItem('user')
    }
  },

  actions: {
    logout({ commit }) {
      commit('CLEAR_AUTH')
    }
  },

  getters: {
    isAuthenticated: state => !!state.token,
    currentUser: state => state.user
  }
}