import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: { 
        requiresAuth: true 
    }
  },  
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/Login.vue'),
    meta: { 
      public: true 
    }
  },
  {
    path: '/jornada/:id',
    name: 'jornada-firma',
    component: () => import('../views/Jornada/JornadaFirma.vue'),
    meta: { 
      requiresAuth: true 
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Guardia de navegación
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.token !== null
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router