// src/main.js
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Importar Bootstrap y BootstrapVue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Importar Moment y configurarlo en español
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

// Configurar Vue
Vue.config.productionTip = false

// Usar BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Importar nuestras variables CSS globales
import '@/assets/styles/variables.css'

// Filtro global para formatear fechas
Vue.filter('formatDate', function(value) {
  if (!value) return ''
  return moment(value).format('DD/MM/YYYY')
})

// Filtro global para formatear horas
Vue.filter('formatTime', function(value) {
  if (!value) return ''
  return moment(value).format('HH:mm')
})

console.log('Mode:', process.env.NODE_ENV)
console.log('API URL:', process.env.VUE_APP_API_URL)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')