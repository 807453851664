import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'

Vue.use(Vuex)

const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost/tfilms-backend/public/'
export default new Vuex.Store({
  state: {
    loading: false,
    baseURL: apiUrl,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value
    }
  },
  modules: {
    auth,
    // jornadas
  }
})